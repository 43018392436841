import React from "react"

import Layout from "../components/Layout"
import styles from "./contact.module.less"

const ContactPage = ({ data }) => {
  const pageData = data.pageData.nodes[0]
  return (
    <Layout
      meta={{
        title: pageData.metaTitle,
        description: pageData.metaDescription.metaDescription,
        image: pageData.metaImage.localFile.publicURL,
      }}
    >
      <div className="container">
        <div className={"page_title"}>
          <h1>{pageData.title}</h1>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-9 col-xl-7">
            {pageData.subtitle.subtitle.split("\n").map((text, idx) => (
              <p key={idx} className={styles.subtitle}>
                {text}
              </p>
            ))}

            <div className={styles.contact}>
              <span>{pageData.emailTitle}</span>
              <a href={`mailto:${pageData.email}`}>{pageData.email}</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    pageData: allContentfulContactPage {
      nodes {
        title
        subtitle {
          subtitle
        }
        email
        emailTitle
        metaTitle
        metaDescription {
          metaDescription
        }
        metaImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
